<template>
  <div>
    <Header>

    </Header>

    <slot/>

    <Footer>

    </Footer>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'

export default {
  name: 'Layout',
  components: { Header, Footer },
  setup () {

  },
}
</script>
