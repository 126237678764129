<template>
  <Layout>
    <router-view />
  </Layout>
</template>

<script>
import Layout from '@/components/Layout.vue'

export default {
  name: 'Start',
  components: { Layout },
  created () {
    if (this.$route.path === '/') this.$router.replace({ name: 'account' })
  },
}
</script>
