<template>
  <div class="home action">
    <h2>Welcome,</h2>
    <p class="h3">What would you like to do today?</p>
    <div class="actions">
      <button id="ride" type="button" @click="setAction(true)" class="action"><img src="../assets/request.svg" alt="Request a Ride"></button>
      <button id="pay" type="button" @click="setAction(false)" class="action"><img src="../assets/addfunds.svg" alt="Add Funds to My Account"></button>
    </div>
  </div>
</template>

<script>
import { useInfo } from '../store'

export default {
  name: 'Action',
  data () {
    return {
      useInfo: useInfo()
    }
  },
  methods: {
    setAction (val) {
      this.useInfo.setInfo({ travel: val })
      this.$router.push({ name: 'info' })
    }
  }
}
</script>
