import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '../public/stylesheets/style.css'
// import '../public/stylesheets/app.css'
const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : ''
})

createApp(App)
  .use(router)
  .use(VueAxios, http)
  .mount('#app')
