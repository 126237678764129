import { createRouter, createWebHistory } from 'vue-router'
import Start from '@/views/Start.vue'
import Action from '@/components/Action.vue'
import Account from '@/components/Account.vue'
import PersonalInfo from '@/components/PersonalInfo.vue'

const routes = [
  {
    path: '/',
    name: 'Start',
    component: Start,
    children: [{
      name: 'account',
      path: 'account',
      component: Account
    }, {
      name: 'action',
      path: 'action',
      component: Action
    }, {
      name: 'info',
      path: 'personal-info',
      component: PersonalInfo
    }]
  },
  {
    path: '/trip-planner',
    name: 'Planner',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "planner" */ '../views/Planner.vue'),
    children: [{
      name: 'customize',
      path: 'customize',
      component: () => import(/* webpackChunkName: "planner" */ '../components/RideMeta.vue'),
    }, {
      name: 'details',
      path: 'details',
      component: () => import(/* webpackChunkName: "planner" */ '../components/RideInfo.vue'),
    }]
  },
  {
    path: '/summary',
    name: 'Summary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "complete" */ '../views/Summary.vue')
  },
  {
    path: '/receipt',
    name: 'Receipt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "complete" */ '../views/Receipt.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0, left: 0 }
  }
})

export default router
