<template>
<div class="info">
  <div class="grid-x grid-padding-x">
    <div class="cell">

      <form name="booking">

        <div class="grid-x grid-padding-x">

          <div class="cell client">

            <h2>Let's get started</h2>
            <p class="h3">Please enter client information below.</p>

            <div class="cell tpfirstname field">
              <label>First Name (required)</label>
              <input v-model="form.first" @blur="validateField('first', $event)" :class="{invalid: !fields.first.valid}" type="text" placeholder="First Name"/>
              <span v-show="!fields.first.valid" class="error">{{ fields.first.message }}</span>
            </div>
            <div class="cell tplastname field">
              <label>Last Name (required)</label>
              <input v-model="form.last" @blur="validateField('last', $event)" :class="{invalid: !fields.last.valid}" type="text" placeholder="Last Name"/>
              <span v-show="!fields.last.valid" class="error">{{ fields.last.message }}</span>
            </div>
            <div class="cell tpemail field">
              <label>Email Address</label>
              <!-- <div class="input-group input-group-rounded"> -->
                <!-- <span class="input-group-label">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                </span> -->
                <input v-model="form.email" class="input-group-field" type="email" id="email" name="email" placeholder="Email Address"/>
                <!-- <span v-show="!fields.email.valid" class="error">{{ fields.email.message }}</span> -->
              <!-- </div> -->
            </div>
            <div class="cell tpphone field">
              <label>Phone Number (required)</label>
              <Cleave
                :raw="false"
                v-model="form.phone"
                placeholder="Phone Number"
                class="small input-group-field"
                :class="{invalid: !fields.phone.valid}"
                type="tel"
                @blur="validateField('phone', $event)"
                :options="{ blocks: [3, 3, 4], delimiter: '-' }"
              />
              <!-- <div class="input-group input-group-rounded"> -->
                <!-- <span class="input-group-label" :class="{invalid: !fields.phone.valid}">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </span> -->
                <!-- <input v-model="form.phone" @input="inputPhone" @blur="validateField('phone', $event)" class="input-group-field" :class="{invalid: !fields.phone.valid}" type="tel" id="phone" name="phone" placeholder="Phone Number" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/> -->
                <p class="sml" style="margin-bottom:0;">Format: 123-456-7890</p>
              <!-- </div> -->
              <span v-show="!fields.phone.valid" class="error">{{ fields.phone.message }}</span>
            </div>

            <div class="cell field">
              <label>Account Number (required)</label>
              <input v-model="form.account" @blur="validateField('account', $event)" :class="{invalid: !fields.account.valid}" type="number" placeholder="Account Number" />
              <span v-show="!fields.account.valid" class="error">{{ fields.account.message }}</span>
            </div>

            <div v-if="!infoState.info.travel" class="cell field">
              <label>Deposit Amount</label>
              <input v-model.number="form.amount" type="number" @blur="validateField('amount', $event)" />
              <span v-show="!fields.amount.valid" class="error">{{ fields.amount.message }}</span>
            </div>

            <input style="position:absolute; right: 0; bottom: 0; width: 0; height: 0; margin: 0; padding: 0; border: none;" v-model="url" type="text" name="url" id="url"/>

          </div>
        </div>
        <button type="button" :disabled="showInvalidSummary" :class="{disabled: showInvalidSummary}" class="primary btn button float-right" @click.prevent="next()">NEXT <img src="../assets/next.svg" alt="Next"></button>
      </form>

    </div>
  </div>
</div>
</template>

<script>
import Cleave from 'vue-cleave-component'
import { useInfo, validateField } from '../store'

export default {
  mounted () {
    for (const field in this.infoState.info) {
      this.form[field] = this.infoState.info[field]
    }
    if (this.form.account) {
      this.validate()
    }
  },
  components: { Cleave },
  data () {
    return {
      infoState: useInfo(),
      form: {
        first: '',
        last: '',
        email: '',
        phone: '',
        account: '',
        amount: 0
      },
      fields: {
        first: {
          valid: true,
          blurred: false,
          message: 'Need a First Name',
          rules: ['required']
        },
        last: {
          valid: true,
          blurred: false,
          message: 'Need a Last Name',
          rules: ['required']
        },
        // email: {
        //   valid: true,
        //   blurred: false,
        //   message: 'Need an Email Address',
        //   rules: ['required']
        // },
        phone: {
          valid: true,
          blurred: false,
          message: 'Need a Phone Number',
          rules: ['required']
        },
        account: {
          valid: true,
          blurred: false,
          message: 'Need an Account Number',
          rules: ['required']
        },
        amount: {
          valid: true,
          blurred: false,
          message: 'Need an amount',
          rules: ['required', 'number']
        }
      },
      url: '', // honeytrap
      invalid: true,
      validityFlags: [],
      invalidMessages: [],
      showInvalidSummary: false
    }
  },
  methods: {
    validateField (fieldkey, evt) {
      if (typeof evt === 'string') evt = { target: { value: evt } }

      this.fields[fieldkey].blurred = true
      this.fields[fieldkey].valid = validateField(fieldkey, evt, this.fields)
      this.validate(evt)
    },
    validate (evt) {
      let valid = true
      this.validityFlags = []
      // $nextTick(() => {
      if (!this.form.first) {
        valid = false
        !this.validityFlags.includes('first') && this.validityFlags.push('first')
      }
      if (!this.form.last) {
        valid = false
        !this.validityFlags.includes('last') && this.validityFlags.push('last')
      }
      // if (!this.form.email) {
      //   valid = false
      //   !this.validityFlags.includes('email') && this.validityFlags.push('email')
      // }
      if (!this.form.phone) {
        valid = false
        !this.validityFlags.includes('phone') && this.validityFlags.push('phone')
      }
      // if (!this.infoState.info.travel && !this.form.account) {
      if (!this.form.account) {
        valid = false
        !this.validityFlags.includes('account') && this.validityFlags.push('account')
      }
      if (!this.infoState.info.travel && !this.form.amount) {
        valid = false
        !this.validityFlags.includes('amount') && this.validityFlags.push('amount')
      }
      this.invalid = !valid
      if (this.showInvalidSummary && valid) this.showInvalidSummary = false
      return valid
      // })
    },
    next () {
      if (this.invalid) {
        if (!this.validityFlags.length) this.validate()
        this.$nextTick(() => {
          this.invalidMessages = this.validityFlags.map(f => ({ field: f, message: this.fields[f].message }))
          for (const field of this.invalidMessages) {
            this.fields[field.field].valid = false
          }
        })
        this.showInvalidSummary = true
        return
      } else {
        // set form info
        if (!this.form.account) this.form.account = this.form.email
        this.infoState.setInfo(this.form)
      }
      if (this.infoState.info.travel) {
        this.$router.push('/trip-planner')
      } else {
        this.$router.push('/summary')
      }
    }

  }
}
</script>
