<template>
  <div class="home account">
    <p class="h3">Please select your account type. <ToolTip tip="Your account type was assigned to you upon registration. For more information, please email us or give us a call (905) 584-2300 or 1-833-552-2748  Ext. 217, 218, or 264" /></p>
    <div class="accounts">
      <button id="transHelp" type="button" @click="setAccount('transHelp')" class="account"><img src="../assets/transhelp.svg" alt="TransHelp"></button>
      <button id="onHealth" type="button" @click="setAccount('onHealth')" class="account"><img src="../assets/ontariohealth.svg" alt="Ontario Health"></button>
      <button id="MTO" type="button" @click="setAccount('MTO')" class="account"><img src="../assets/mto.svg" alt="MTO"></button>
    </div>
  </div>
</template>

<script>
import { useInfo } from '../store'
import ToolTip from '@/components/ToolTip.vue'

export default {
  name: 'Start',
  components: {
    ToolTip
  },
  data () {
    return {
      useInfo: useInfo()
    }
  },
  methods: {
    setAccount (val) {
      this.useInfo.setInfo({ type: val })
      if (val !== 'transHelp') {
        this.$router.push({ name: 'action' })
      } else {
        this.useInfo.setInfo({ travel: true })
        this.$router.push({ name: 'info' })
      }
    }
  }
}
</script>
