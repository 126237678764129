import { ref, computed } from 'vue'

const infoTpl = {
  account: '',
  type: '',
  first: '',
  last: '',
  email: '',
  phone: '',
  travel: true,
  amount: 0
}
const rideTpl = {
  city: '',
  origin: '',
  escort: '',
  thirdParty: {
    thirdParty: false,
    thirdPartyfirstName: '',
    thirdPartylastName: '',
    thirdPartyphone: '',
    thirdPartyemail: ''
  },
  purpose: '',
  mobilityAid: false,
  startAddress: {
    street: '',
    number: '',
    apt: '',
    city: '',
    postalCode: ''
  },
  destinationAddress: {
    street: '',
    number: '',
    apt: '',
    city: '',
    postalCode: ''
  },
  destinationDetails: '',
  returnTrip: false,
  companion: 0,
  price: 0,
  total: 0,
  arriveTimestamp: '',
  date: '',
  time: '',
  pickupAddress: '',
  pickupTimestamp: '',
  pickupTime: '',
  returnAddress: ''
}
const receiptTpl = {
  order_no: '',
  cust_id: '',
  transaction_no: '',
  reference_no: '',
  transaction_code: '',
  transaction_type: '',
  transaction_date_time: '',
  corporate_card: '',
  amount: '',
  response_code: '',
  iso_response_code: '',
  approval_code: '',
  card_type: '',
  eci: '',
  cvd_result_code: '',
  is_debit: '',
  ecr_no: '',
  batch_no: '',
  sequence_no: '',
  result: '',
  ref: ''
}

const info = ref(Object.create(infoTpl))

const ride = ref(Object.create(rideTpl))

const receipt = ref(Object.create(receiptTpl))
// const travel = ref(true)

// function useTravel () {
//   return {
//     travel: computed(() => travel.value),
//     setTravel (val) {
//       travel.value = val
//     }
//   }
// }

function useInfo () {
  return {
    info: computed(() => info.value),
    setInfo (val) {
      // console.info(' ::: store.useInfo.setInfo', val)
      info.value = Object.assign(info.value, val)
      // console.info(' ::: store.useInfo.setInfo updated', info.value)
    },
    clear () {
      info.value = Object.create(infoTpl)
    }
  }
}

function useRide () {
  return {
    ride: computed(() => ride.value),
    setRide (val) {
      // console.info(' ::: store.useRide', val)
      ride.value = Object.assign(ride.value, val)
      // console.info(' ::: store.useRide updated', ride.value)
    },
    clear () {
      ride.value = Object.create(rideTpl)
    }
  }
}

function useTxn () {
  return {
    receipt: computed(() => receipt.value),
    setReceipt (val) {
      // console.info(' ::: store.useRide', val)
      receipt.value = Object.assign(receipt.value, val)
      // console.info(' ::: store.useRide updated', ride.value)
    },
    clear () {
      receipt.value = Object.create(receiptTpl)
    }
  }
}

function validateField (fieldkey, evt, fields) {
  const key = fieldkey.replace('.', '')
  // fields[key].blurred = true
  const rules = {
    eq (max, val) {
      return val.length === max
    },
    lt (max, val) {
      return val.length < max
    },
    lte (max, val) {
      return val.length <= max
    },
    gt (max, val) {
      return val.length > max
    },
    gte (max, val) {
      return val.length >= max
    },
    required (val) {
      let result = false
      if (typeof val === 'string') result = !!val
      if (typeof val === 'number') result = true
      if (typeof val === 'object') result = !!(val.length || Object.keys(val).length)
      return result
    },
    number (val) {
      let result = false
      if (typeof val === 'number') result = true
      result = !isNaN(parseInt(val))

      return result
    },
    address (val) {
      if (typeof val === 'string') return true
      if (val.number && val.street && val.city) return true
      return false
    }
  }
  const fieldRules = fields[key].rules
  const valid = []
  for (const rule of fieldRules) {
    if (~rule.indexOf(' ')) {
      const compoundRule = rule.split(' ')
      valid.push(rules[compoundRule[0]](compoundRule[1], evt.target.value))
    } else {
      valid.push(rules[rule](evt.target.value))
    }
  }
  // console.log(' ::: store.validate', fieldkey, evt.target.value, valid.every(val => val))
  return valid.every(val => val)
}

const cities = [{
  name: 'Alton',
  label: 'Alton',
  price: 4
}, {
  name: 'Belfountain',
  label: 'Belfountain',
  price: 4
}, {
  name: 'Bolton',
  label: 'Bolton',
  price: 4
}, {
  name: 'Caledon',
  label: 'Caledon',
  price: 4
}, {
  name: 'Caledon East',
  label: 'Caledon East',
  price: 4
}, {
  name: 'Caledon Village',
  label: 'Caledon Village',
  price: 4
}, {
  name: 'Cheltenham',
  label: 'Cheltenham',
  price: 4
}, {
  name: 'Inglewood',
  label: 'Inglewood',
  price: 4
}, {
  name: 'Mono Mills',
  label: 'Mono Mills',
  price: 4
}, {
  name: 'Palgrave',
  label: 'Palgrave',
  price: 4
}, {
  name: 'Southfields',
  label: 'Southfields',
  price: 4
}, {
  name: 'Terra Cotta',
  label: 'Terra Cotta',
  price: 4
}, {
  name: 'Valleywood',
  label: 'Valleywood',
  price: 4
}, {
//   name: 'Caledon',
//   label: 'Caledon',
//   price: 4
// }, {
  name: 'Orangeville',
  label: 'Orangeville',
  price: 5
}, {
  name: 'Brampton',
  label: 'Brampton',
  price: 10
}, {
  name: 'Georgetown',
  label: 'Georgetown',
  price: 10
}, {
  name: 'Etobicoke',
  label: 'Etobicoke',
  price: 10
}, {
  name: 'Woodbridge',
  label: 'Woodbridge',
  price: 10
}, {
  name: 'Newmarket',
  label: 'Newmarket',
  price: 10
}, {
  name: 'Mississauga',
  label: 'Mississauga',
  price: 20
}, {
  name: 'Toronto',
  label: 'Toronto, GTA',
  price: 30
}]

const origins = [{
  name: 'Alton',
  label: 'Alton',
  price: 4
}, {
  name: 'Belfountain',
  label: 'Belfountain',
  price: 4
}, {
  name: 'Bolton',
  label: 'Bolton',
  price: 4
}, {
  name: 'Caledon',
  label: 'Caledon',
  price: 4
}, {
  name: 'Caledon East',
  label: 'Caledon East',
  price: 4
}, {
  name: 'Caledon Village',
  label: 'Caledon Village',
  price: 4
}, {
  name: 'Cheltenham',
  label: 'Cheltenham',
  price: 4
}, {
  name: 'Inglewood',
  label: 'Inglewood',
  price: 4
}, {
  name: 'Mono Mills',
  label: 'Mono Mills',
  price: 4
}, {
  name: 'Palgrave',
  label: 'Palgrave',
  price: 4
}, {
  name: 'Southfields',
  label: 'Southfields',
  price: 4
}, {
  name: 'Terra Cotta',
  label: 'Terra Cotta',
  price: 4
}, {
  name: 'Valleywood',
  label: 'Valleywood',
  price: 4
}]
// const taxes = {
//   ON: {name: 'HST', rate: .13}
// }
const dateFormats = {
  standard: 'MMMM d, y at h:m bbb',
  day: 'MMMM d, y',
  ymd: 'y-LL-dd'
}

export {
  validateField,
  cities,
  origins,
  dateFormats,
  useInfo,
  useRide,
  useTxn
}
