<template>
  <span class="tt" @mouseover="showTT = true" @mouseleave="showTT = false"><img src="../assets/infoicon.svg" alt="Info">
    <div v-if="showTT" class="tip" v-html="tip"></div>
  </span>
</template>

<script>
export default {
  name: 'ToolTip',
  props: {
    tip: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showTT: false
    }
  },
}
</script>
